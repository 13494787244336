// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-anxiety-js": () => import("./../../../src/pages/anxiety.js" /* webpackChunkName: "component---src-pages-anxiety-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-compulsive-js": () => import("./../../../src/pages/compulsive.js" /* webpackChunkName: "component---src-pages-compulsive-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-depression-js": () => import("./../../../src/pages/depression.js" /* webpackChunkName: "component---src-pages-depression-js" */),
  "component---src-pages-eating-js": () => import("./../../../src/pages/eating.js" /* webpackChunkName: "component---src-pages-eating-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-method-js": () => import("./../../../src/pages/method.js" /* webpackChunkName: "component---src-pages-method-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-personality-js": () => import("./../../../src/pages/personality.js" /* webpackChunkName: "component---src-pages-personality-js" */),
  "component---src-pages-ptsd-js": () => import("./../../../src/pages/ptsd.js" /* webpackChunkName: "component---src-pages-ptsd-js" */),
  "component---src-pages-theraphy-js": () => import("./../../../src/pages/theraphy.js" /* webpackChunkName: "component---src-pages-theraphy-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

